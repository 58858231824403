import { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import useSidebarStore from "../../store/sidebar";
import useTicketFilterStore from "../../store/filters/tickets";

import { BrowserView, MobileView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";

import { TbCheckbox } from "react-icons/tb";

import TicketsList from "../../components/Ticket/List";

import ListWrapper from "../../UI/ListWrapper";

import TicketFilter from "../../components/Ticket/Filter";

import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const Tickets = () => {
  const { setLeftSidebarContent } = useSidebarStore();

  const { tickets } = useLoaderData();

  const filterStore = useTicketFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetchOpened();
  }, []);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <TicketFilter items={tickets} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, tickets]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const iAmResponsibleToggleHandler = () => {
    filterStore.updateFilter({
      ...filterStore,
      iAmResponsible: !filterStore.iAmResponsible,
    });
    filterStore.applyFilter();
  };

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <TbCheckbox /> Заявки
      </>
    );
  };

  return (
    <>
      <ListWrapper
        title={title}
        filteredList={filterStore.filteredList}
        filterStore={filterStore}
        onUpdatePage={filterStore.fetchOpened}
        secondaryList={tickets}
        isLoading={filterStore.isLoading}
        hideLengthRow
        addRoute="/tickets/add"
      >
        <MobileView>
          <Row className="my-3">
            <Col>
              <Form.Check
                type="switch"
                className="form-control-lg"
                id="i-am-responsible"
                label="Назначены на меня"
                value={filterStore.iAmResponsible}
                checked={filterStore.iAmResponsible}
                onChange={iAmResponsibleToggleHandler}
              />
            </Col>
          </Row>
          <Offcanvas
            show={showOffcanvas}
            onHide={() => {
              setShowOffcanvas(false);
            }}
          >
            <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
            <Offcanvas.Body>
              <TicketFilter
                items={tickets}
                setShowOffcanvas={setShowOffcanvas}
              />
            </Offcanvas.Body>
          </Offcanvas>
          <Row className="justify-content-between text-secondary my-3  fs-5">
            <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
            <Col
              className="text-end text-success"
              onClick={showOffcanvasChangeHandler}
            >
              <u>Фильтр</u>
            </Col>
          </Row>
        </MobileView>
        <BrowserView>
          <Row className="justify-content-between text-secondary my-3  fs-5">
            <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
          </Row>
        </BrowserView>
        <TicketsList items={filterStore.filteredList}></TicketsList>
      </ListWrapper>
    </>
  );
};

export default Tickets;

export async function loader() {
  document.title = "ЗАЯВКИ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/tickets/all-opened`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}
